.HiwBox {
    width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-img {
    width:100%;
    height: auto;
    margin-bottom: 20px;
}

.number {
    border-radius: 50%;
    width: 80px;
    height: auto;
    margin-bottom: 10px;
}

.HiwBox p {
    margin-bottom: 10px;
}



@media screen and (max-width: 480px) {
    .HiwBox {
        width: 280px;
    }
}