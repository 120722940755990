.AllChallenges {
    width: 100%;
    margin: 50px 0px;
}

.allChallenge-title {
    color: var(--PrimaryColor);
    font-size: 48px;
    text-align: center;
    margin-bottom: 10px;
}


.AllChallenges-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.AllChallenges-content p {
    color: var(--PrimaryColor);
    
}

.allChallenge-description {
    text-align: center;
}

.AllChallenges-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 40px;
    margin: 50px 0px;
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    flex-direction: column;
}