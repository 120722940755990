.NewChallenge {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.NewChallenge .title {
    font-size: 48px;
    margin-bottom: 30px;
    text-align: center;
    color: var(--PrimaryColor);
}

.NewChallenge .dashboard-title {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    color: var(--PrimaryColor);
}

.challenges {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
    
}

hr {
    color: var(--BorderColor);
    width: 100%;
    margin: 50px 0px;
}



.NewChallenge button {
    width: 163px;
    height: 36px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: var(--AccentColor);
    color: var(--WhiteColor);
    cursor: pointer;
    margin-top: 40px;
    transition: background-color 0.3s;
}

.NewChallenge button:hover {
    background-color:#e55b50;
}

@media screen and (max-width:978px ) {
    .title {
        font-size: 42px;
    }
}

@media screen and (max-width: 768px) {
    .challenges {
        flex-direction: column;
    }

    .title {
        font-size: 36px;
    }
}

@media screen and (max-width: 480px) {
    .title {
        font-size: 30px;
    }
}