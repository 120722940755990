@import url('https://fonts.googleapis.com/css2?family=Alata&family=Inter:wght@100..900&display=swap');


.Competition .title {
    font-size: 48px;
    margin-bottom: 50px;
    text-align: center;
    color: var(--PrimaryColor);
}

.competition-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.competition-content-img {
    width: 35%;

}

.competition-content-description {
    width: 35%;
}

.competition-content-img img {
    width: 100%;
    height: auto;
    border-radius: 18px;
}

.competition-content-description h2{
    color: var(--PrimaryColor);
    font-size: 40px;
    margin-bottom: 20px;
}

.competition-content-description p {
    color: var(--PrimaryColor);
    margin-bottom: 30px;
}


.competition-content-description button {
    width: 163px;
    height: 36px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: var(--AccentColor);
    color: var(--WhiteColor);
    cursor: pointer;
    transition: background-color 0.3s;
}

.competition-content-description button:hover {
    background-color:#e55b50;
}



@media screen and (max-width: 1200px) {
    .competition-content-description h2 {
        font-size: 36px;
    }
}

@media screen and (max-width:978px ) {
    .Competition  .title {
        font-size: 42px;
    }

    .competition-content {
        flex-direction: column;
    }

    .competition-content-img {
        width: 80%;
    }

    .competition-content-description {
        width: 80%;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .competition-content-description h2 {
        font-size: 32px;
    }

    .Competition .title {
        font-size: 36px;
    }
}

@media screen and (max-width: 480px) {
    .competition-content-description h2 {
        font-size: 26px;
    }

    .Competition .title {
        font-size: 30px;
    }
}




