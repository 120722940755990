.HIW {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.HIW h1 {
    color: var(--PrimaryColor);
    font-size: 48px;
    margin-bottom: 40px;
}

.HIW p {
    color: var(--PrimaryColor);
}

.HIW-content {
    display: flex;
    width: 90%;
    justify-content: center;
    gap: 80px;
}

@media screen and (max-width: 978px) {
    .HIW > .title {
        font-size: 42px;
    }
}

@media screen and (max-width: 768px) {
    .HIW-content {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .HIW p {
        display: flex;
    }

}

@media screen and (max-width: 480px) {
    .HIW > .title {
        font-size: 30px;
    }
}