.Profile-content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 50px;
    min-height: 70vh;
}

.profile-content-left {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.profile-content-left-box {
    border: 1px solid var(--BorderColor);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.profile-content-left-box p {
    text-align: center;
    color: var(--PrimaryColor);
}

.profile-content-left-box h2 {
    color: var(--PrimaryColor);
}

.profile-content-left-box1-img {
    width: 80px;
    height: 80px;
    
}

.profile-content-left-box1-img img {
    width: 100%;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.left-box2 .profile-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.profile-content-left-box2-social-links a {
    text-decoration: none;
    color: var(--PrimaryColor);
    transition: color 0.3s;

}

.profile-content-left-box2-social-links a:hover {
    color: var(--AccentColor);

}


.profile-content-left-box2-social-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.profile-content-right {
    display: flex;
    flex-direction: column;
    color: var(--PrimaryColor);
    justify-content: flex-start;
    gap: 20px;
    align-items: flex-start;
    width: 100%;
}


.submission-challenge {
    display: flex;
    height: auto;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.profile-skeleton {
    width: 300px;
}

@media screen and (max-width: 768px) {
    .Profile-content {
        flex-direction: column;
    }
}