.Sidebar {
    width: 250px;
    min-height: fit-content;
    border: 1px solid var(--BorderColor);
    border-radius: 18px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
}

.Sidebar-hide {
    display: none;
}

.sidebar-profile {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    color: var(--PrimaryColor);
    position: relative;
}

.sidebar-profile-close-icon {
    position: absolute;
    left: 0;
    top: 0;
    transition: color 0.3s;
    cursor: pointer;
    font-size: 22px;
}

.sidebar-profile-close-icon:hover {
    color: var(--AccentColor);
}

.sidebar-profile-img {
    width: 54px;
    height: 54px;
}

.sidebar-profile-img img {
    width: 100%;
    height: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 100%;
}

.sidebar-line {
    width: 100%;
    border: 1px solid var(--BorderColor);
    margin-bottom: 30px;
}

.sidebar-links {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

}

.sidebar-links a {
    text-decoration: none;
    color: var(--TextColor);
}

.sidebar-links li  {
    width: 100%;
    display: block;
    height: 40px;
    border: 1px solid var(--BorderColor);
    border-radius: 8px;
    transition: border-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-links li a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: color 0.3s;
    color: var(--PrimaryColor);
    cursor: pointer;
}


.sidebar-links li:hover a {
    color: var(--AccentColor);
    transition: color 0.3s;
    cursor: pointer;
}


.sidebar-links li:hover {
    border-color: var(--AccentColor);
}

.sidebar-icon-container:hover {
    border-color: var(--AccentColor);
    transition: border-color 0.3s;
}

.sidebar-icon-container .sidebar-icon {
    color: var(--PrimaryColor);
}


.sidebar-icon-container:hover .sidebar-icon{
    color: var(--AccentColor);

}

.sidebar-menu-container .sidebar-icon {
    cursor: pointer;
    transition: color 0.3s;
}

.sidebar-menu-container {
    transition: border-color 0.3s;
}


.sidebar-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    display: none;
}

.sidebar-icon-container {
    width: 40px;
    height: 40px;
    border: 1px solid var(--BorderColor);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.sidebar-menu-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sidebar-links-hide {
    display: none;
}

.sidebar-menu-container {
    border: 1px solid var(--BorderColor);
    display: block;
    width: 60px;
    border-radius: 18px;
    padding: 20px 0px;
}

.sidebar-menu-container-hide {
    display: none;
}

.user-profile-username {
    text-align: center;
    font-size: 14px;

}
/* Target challenge */

.targetChallengeContainer {
    width: 100%;
    padding-left: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.steps {
    position: relative;
    transition: color 0.3s ease;
}

.steps:hover .targetChallengeArrow {
    color: var(--AccentColor);
}


.targetChallengeArrow {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: var(--PrimaryColor);
    cursor: pointer;
    transition: color 0.3s ease;
}

.targetChallenge-link div {
    color: var(--PrimaryColor);
    display: flex;
    gap: 5px;
    transition: color 0.3s ease;
}

.targetChallenge-link div:hover {
    color: var(--AccentColor);
}

.targetActive-link div{
    color: var(--AccentColor); 
}




/* For target challenges border and color when it is active */

.sidebar-links .targetChallengeContainer .targetActive-link {
    border-color: var(--AccentColor);
}

.targetActive-link div a {
    color: var(--AccentColor); 
    border-color: var(--AccentColor);
}



.targetChallenge-link div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.targetChallengeContainer {
    max-height: 0;
    display: none;
    transition: max-height 0.5s ease;
    width: 100%;
}

.targetChallengeContainer-active {
    max-height: fit-content;
    display: flex;
}

.sidebar-skeleton  {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    height: 100%;
}

.mobile-menu-icon {
    display: none;
}

.mobile-close-icon {
    display: none;
}

@media screen and (max-width: 590px) {
    .Sidebar {
        display: block;
        position: absolute;
        width: 92%;
        background-color: var(--BackgroundColor);
        z-index: 1000;
        overflow-y: auto;
        
    }

    .desktop-close-icon {
        display: none;
    }

    .mobile-close-icon {
        display: flex;
    }

    .desktop-menu-icon {
        display: none;
    }

    .mobile-menu-icon {
        display: flex;
    }
}

@media screen and (max-width: 480px) {
    .Sidebar {
        width: 91.3%;
    }
}



