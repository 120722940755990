.challenge {
    flex: 1 1 calc(33.333% - 20px);
    max-width: 390px;
    min-width: 250px;
    height: 380px;
    background-color: var(--BoxColor);
    border-radius: 18px;
    cursor: pointer;
    transition: transform 0.3s ease;
    position: relative;
    
}

.challenge img {
    width: 100%;
    height: auto;
    
}

.challenge h3 {
    color: var(--PrimaryColor);
    margin-bottom: 7px;
    padding: 10px 15px 0px;
    font-size: 20px;
}

.challenge-description {
    color: var(--PrimaryColor);
    padding: 0px 15px 30px;
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow:ellipsis;
    line-height: 1.6em;
}

.challenge:hover {
    transform: translateY(-5px);
}

.difficulty-text {
    padding: 5px 10px;
    border-radius: 8px;
    position: absolute;
    top: -10px;
    right: -10px;
    color: var(--WhiteColor);

}

.difficulty-easy {
    background-color: var(--DifficultyGreen);
}

.difficulty-intermediate {
    background-color: var(--DifficultyOrange);
}

.difficulty-hard {
    background-color: var(--DifficultyRed);
}


.skeleton {
    width: 100%; 
    height: 100%; 
    border-radius: 18px; 
    background-color: var(--SkeletonColor); 
}
