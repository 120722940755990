.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px;
    width: 90%;
    margin: 0 auto;
}

.contact-content {
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 50%;
}

.contact-container h1 {
    font-size: 48px;
    color: var(--PrimaryColor);
}

.contact-container p {
    color: var(--PrimaryColor);
}

.accent-color {
    color: var(--AccentColor);
}


.contact-img img{
    width: 100%;
    height: auto;
}


@media screen and (max-width:1200px) {
    .contact-container {
        gap: 80px;
    }

    .contact-container h1 {
        font-size: 42px;
    }
}

@media screen and (max-width: 978px) {
    .contact-container {
        gap: 30px;
    }

    .contact-container {
        width: 100%;
    }

    
}

@media screen and (max-width: 768px) {
    .contact-container {
        flex-direction: column-reverse;
    }

    .contact-container h1 {
        font-size: 36px;
    }
}

@media screen and (max-width: 480px) {
    .contact-container h1 {
        font-size: 30px;
        text-align: center;
    }

    .contact-content button {
        margin: 0 auto;
        width: 150px;
    }
}