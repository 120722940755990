@import url('https://fonts.googleapis.com/css2?family=Alata&family=Inter:wght@100..900&display=swap');


.Homepage-content {
    text-align: center;
    margin-top: 100px;
}

.Homepage-content h1 {
    color: var(--PrimaryColor);
    font-size: 64px;
    max-width: 830px;
    margin: auto;
}

.Homepage-content p {
    color: var(--PrimaryColor);
    font-size: 24px;
    max-width: 760px;
    margin: auto;
    padding-top: 20px;
}

.Homepage-content button {
    width: 250px;
    height: 43px;
    outline: none;
    border: none;
    color: var(--WhiteColor);
    background-color: var(--AccentColor);
    margin: 20px 0px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Homepage-content button:hover {
    background-color: var(--HoverColor);
}

.Homepage-banner {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 90%;
    margin: auto;
}

.Homepage-banner img {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-top: 70px;
}

hr {
    color: var(--BorderColor);
    width: 100%;
    margin: 50px 0px;
}

@media (max-width: 768px) {
    .Homepage-content h1 {
        font-size: 52px;
        max-width: 600px;
        margin: auto;
    }
    
    .Homepage-content p {
        font-size: 16px;
        max-width: 460px;
        margin: auto;
        padding-top: 20px;
    }

    .Homepage-banner {
        display: none;
    }
}

@media (max-width: 480px){
    .Homepage-content h1 {
        font-size: 32px;
        padding: 0px 20px;
        margin: auto;
    }
    
    .Homepage-content p {
        font-size: 16px;
        padding: 0px 20px;
        margin: auto;
        padding-top: 20px;
    }
}


