.Achievement {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--OverlayColor);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.achievement-container {
    background-color: var(--BackgroundColor);
    min-width: 250px;
    width: 450px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 18px;
    color: var(--PrimaryColor);
    position: relative;
}

.achievement-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: -20px;
}

.achievement-content h2 {
    text-align: center;
}

.achievement-content p {
    text-align: center;
}

.social-share-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.social-share-buttons button{
    cursor: pointer;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    background-color: var(--AccentColor);
    color: var(--WhiteColor);
    border: none;
    outline: none;
    transition: background-color 0.3s;
}

.social-share-buttons button:hover {
    background-color: var(--HoverColor);
}

.share-icon {
    font-size: 20px;
}

.achievement-img {
    width: 200px;
    height: auto;
    margin: 0 auto;

}

.achievement-img img {
    width: 100%;
    
}

.achievement-close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

.no-scroll {
    overflow: hidden; 
}



