.leaderboard-table {
    color: var(--PrimaryColor);
}

.leaderboard-table-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--BorderColor);
    border-radius: 18px;
    padding: 0px 20px;
    height: 80px;
    margin-bottom: 20px;
}

.leaderboard-rank {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.leaderboard-rank-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.leaderboard-rank-profile-img {
    width: 40px;
    height: auto;
}

.leaderboard-rank-profile-img img {
    width: 100%;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.leaderboard-table-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    margin: 30px 0px 20px;
}

.leaderboard-table-heading p {
    font-weight: 900;
}

.userName {
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .leaderboard-rank {
        gap:20px;
    }
}
