@import url('https://fonts.googleapis.com/css2?family=Alata&family=Inter:wght@100..900&display=swap');


.Navbar {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    border: 1px solid var(--BorderColor);
    border-radius: 14px;
    padding:0px 20px;
    box-sizing: border-box;
    color: var(--PrimaryColor);
    position: relative;
}


.Navbar-logo-image{
    flex-shrink: 0;
    cursor: pointer;
    width: 180px;
    height: auto;
}

.Navbar-logo-image img {
    width: 100%;
}

.Navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
}

.Navbar ul {
    list-style-type:none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 330px;
    margin: 0px;
    padding: 0px;
}

.Navbar a {
    text-decoration: none;
    color: var(--PrimaryColor);
    transition: color 0.3s;
}

.Navbar a:hover {
    color: var(--HoverColor);
}


.Navbar ul li a.active {
    color: var(--AccentColor);
}

.Navbar-buttons {
    width: 192px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Navbar-button {
    width: 140px;
    height: 50px;
    background-color: var(--AccentColor);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    font-family: 'alata';
    transition: background-color 0.3s;
}

.Navbar-button:hover {
    background-color: var(--HoverColor);
}

.mobile-sidebar-login-btn button {
    width: 140px;
    height: 50px;
    background-color: var(--AccentColor);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    font-family: 'alata';
    transition: background-color 0.3s;
}

.mobile-sidebar-login-btn button{
    background-color: var(--HoverColor);
}

.Navbar-menu {
    display: none;
}

.mobile-button {
    display: none;
}

.login-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: var(--PrimaryColor);
}

.login-profile-img {
    width: 50px;
    height: 50px;
}

.login-profile-img img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.profile-dropdown {
    position: absolute !important;
    top: 75px !important;
    right: 0px !important;
    display: flex;
    flex-direction: column;
    gap: 20px !important;
    border: 1px solid var(--BorderColor) !important;
    background-color: var(--BackgroundColor); 
    border-radius: 18px;
    width: 140px;
    padding: 20px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 9999;
}

.profile-dropdown-button {
    transition: color 0.3s;
    background: none;
    border: none;
    outline: none;
    color: var(--PrimaryColor);
    font-size: 16px;
    cursor: pointer;
}

.profile-dropdown-button:hover {
    color: var(--AccentColor);
}

.users-mobile-navbar-links {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.mobile-signout-btn {
    color: var(--WhiteColor) !important;
}

.mobile-sidebar-login-btn {
    display: none;
}

.points-img {
    width: 35px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
}

.points-img img {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .Navbar-menu {
        display: block;
        margin-left: 20px;
    }

    .Navbar-links {
        display: none;
    }

    .Navbar-button {
        width: 100px;
        display: none;
    }

    .Navbar-links-active {
        display: block;
        position: fixed;
        top: 100px;
        right: 0px;
        width: 100%;
        height: 100vh;
        background-color: #2a3239;
        z-index: 9999;
    }

    .mobile-button {
        display: block;
    }

    .Navbar-links ul {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-top:50px ;
        margin: 0 auto;

    }

    .Navbar-links a{
        color: var(--WhiteColor ) ;
    }

    .users-mobile-navbar-links {
        display: flex;
    }

    .login-profile {
        display: none;
    }
    
    .navbar-skeleton  {
        display: none;
    }


    .mobile-sidebar-login-btn {
        display: block;
    }
}
