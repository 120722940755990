.ChallengeDisplay {
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.challengeDisplay-box {
    width: 90%;
    height: 70%;
    background-color: var(--BoxColor);
    margin:0 auto;
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.challengeDisplay-box-img {
    width: 80%;
    height: auto;
    padding: 30px;
}

.challengeDisplay-box-img img {
    width: 100%;
    height: auto;
    display: flex;
}

.challengeDisplay-box-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 30px;
    cursor: pointer;
}

.challengeDisplay-box-buttons > button {
    width: 144px;
    height: 36px;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: var(--SecondaryColor);
    color: var(--WhiteColor);
    cursor: pointer;
}

.challengeDisplay-box-buttons > button:hover {
    background-color:#7a8a99
}

.active-btn {
    background-color: var(--PrimaryColor)!important;
}

.challengeDisplay-content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;

}

.challengeDisplay-content-box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.challengeDisplay-challengeDescription {
    flex: 1;

}

.challengeDisplay-assets {
    flex:1;
}

.contentBox {
    padding: 30px;
    border-radius: 28px;
    background-color: var(--BoxColor);
    box-sizing: border-box;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.challengeDisplay-content-box2 {
    padding: 30px;
    border-radius: 28px;
    background-color: var(--BoxColor);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.challengeDisplay-content h1,p{
    color: var(--PrimaryColor);
}

.challengeDisplay-content h1{
    font-size: 32px;
}

.challengeDisplay-assets > ul li {
    color: var(--PrimaryColor);
}

.challengeDisplay-assets > ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.challengeDescription-start-button, .challengeDescription-submit-button {
    padding: 8px 15px;
    background-color: var(--AccentColor);
    border-radius: 4px;
    border: none;
    outline: none;
    color: var(--WhiteColor);
    transition: background-color 0.3s;
}

.challengeDescription-start-button:hover, .challengeDescription-submit-button:hover{
    background-color: #e55b50;
}

.challengeDisplay-challengeDescription button {
    padding: 8px 20px;
    cursor: pointer;
}

.challengeDisplay-content-box2 button {
    width: 180px;
    height: 36px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: -20px;
}

.no-scroll {
    overflow: hidden !important;
    height: 100%; 
}

.challengeDisplay-challengeDescription-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.challengeDisplay-status {
    border: 1px solid rgb(6, 167, 6);
    border-radius: 18px;
    background-color: transparent;
    color: rgb(6, 167, 6);
    display: none;
}


.challengeDisplay-status-active {
    display: block;
    border: 1px solid rgb(6, 167, 6);
    border-radius: 18px;
    background-color: transparent;
    color: rgb(6, 167, 6);
}
.challengeDisplay-input-links {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    outline: none;
    border-radius: 5px;
    color: var(--PrimaryColor);
    transition: border-color 0.3s;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.challengeDisplay-input-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 120px;
}

.challengeDisplay-input-box input {
    background-color: transparent;
}

/* ChallengeDisplay.css */

.spinner-container {
    position: fixed; /* Or absolute, depending on your layout */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: add a slight background overlay */
    z-index: 9999; /* Ensure spinner is on top of other elements */
}


@media screen and (max-width: 978px) {
    .challengeDisplay-box-buttons > button {
        width: 122px;
        height: 36px;
    }

}

@media screen and (max-width: 768px) {
    .challengeDisplay-content-box1 {
        flex-direction: column;
    }

    .contentBox {
        width: 100%;
    }

    .challengeDisplay-box-buttons > button {
        width: 80px;
        height: 36px;
    }

    .challengeDisplay-box-buttons {
        gap: 10px;
    }
    
}

@media screen and (max-width: 480px) {
    .challengeDisplay-box-buttons > button {
        width: 70px;
        height: 26px;
    }
    
    .contentBox {
        padding: 20px;
    }

    .challengeDisplay-content-box2 {
        padding: 20px;
    }

    .challengeDisplay-content h1{
        font-size: 28px;
    }

    .challengeDisplay-content-box2 {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
    }
    
}

@media screen and (max-width: 430px) {
    .challengeDescription-start-button {
        width: 130px;
    }
}