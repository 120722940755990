@import url('https://fonts.googleapis.com/css2?family=Alata&family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
}

:root {
    --PrimaryColor: #5a647d;
    --SecondaryColor:  #a1b2c3;
    --AccentColor: #ff6f61;
    --BackgroundColor: #f3f3fa;
    --TextColor: #4a4a4a;
    --BlackColor: #090917;
    --WhiteColor: #ffffff;
    --BorderColor: #BDC1CA;
    --HoverColor: #E65C50;
    --BoxColor: #EBEBF7;
    --DarkBlue: #171A1F;
    --OverlayColor: rgba(49,49,49,0.8);
    --DifficultyGreen: #90C890;
    --DifficultyOrange: #FFA500;
    --DifficultyRed: #FF0000;
}


body {
    padding: 0;
    font-family: 'Alata', 'Helvetica Neue', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f3f3fa !important;
    width: calc(100% - 40px) !important; 
    max-width: 1440px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

button {
    font-family: 'alata' ;
    font-size: 15px;
}

hr {
    color: var(--BorderColor);
    width: 100%;
    margin: 50px 0px;
}

input::placeholder {
    font-family: 'alata';
}

textarea::placeholder {
    font-family: 'alata';
}

input {
    font-family: 'alata';
}

textarea {
    font-family: 'alata';
}