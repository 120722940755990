.dashboard-home {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
}

.dashboard-content {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    gap: 20px; 
    width: 50%;
}

.Contribution-graph {
    width: 100%;
    padding: 20px;
    box-sizing: border-box; 
}

.dashboard-content {
    transition: all 0.3s ease; /* Optional smooth transition */
}

.hide-content {
    display: none; /* Or visibility: hidden; if you want to keep layout space */
}

.footer-space {
    margin-top: 800px;
}

@media screen and (max-width: 480px){
    .dashboard-home{
        padding: 0px;
    }
}

@media screen and (max-width: 590px) {
    
}